import React, { useEffect } from 'react';
import Loader from '@components/Loader/index';
import { graphql } from 'gatsby';

export default function FdtStylePage() {
  useEffect(() => {
    window.open('/404', '_self');
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Loader></Loader>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    fdtStylePagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      canonicalUrl
      SEOTitle
      SEODescription

      heroTitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }
      videoText
      videoLink

      stats {
        title
        subtitle
      }

      highlightsTitle
      highlightsDescription
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      price
      priceDuration

      technologies {
        name
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculumTitle
      curriculumDescription
      curriculumQuote
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        points
        duration
        tag
        data
        skills
        anchor
      }
      projectsTitle
      projectsDescription
      projects {
        title
        subtitle
        tag
        image
        skills
      }

      faqs {
        question
        answer
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
